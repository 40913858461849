import Head from 'next/head'
import { useRouter } from 'next/router'
import Script from 'next/script'

type propType = {
  title: string
  previewDescription: string
  previewImage: string
  description?: string
}

const slogan = 'Turn your wants into wins with BidFTA Online Auctions'

const SeoHead = ({
  title,
  previewDescription = '',
  previewImage,
  description = slogan,
}: propType) => {
  const router = useRouter()

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta
          name="p:domain_verify"
          content="ee2d3cb138b72ebbf47b68d101ac1b9f"
        />
        {/* Facebook Meta Tags */}
        <meta
          property="og:url"
          content={`${process.env.NEXT_PUBLIC_URL}${
            router.asPath !== '/' ? router.asPath : ''
          }`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta
          property="og:description"
          content={previewDescription.replace(/(<([^>]+)>)/gi, '')}
        />
        <meta property="og:image" content={previewImage} />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="" />
        <meta
          property="twitter:url"
          content={`${process.env.NEXT_PUBLIC_URL}${
            router.asPath !== '/' ? router.asPath : ''
          }`}
        />
        <meta name="twitter:title" content={title} />
        <meta
          name="twitter:description"
          content={previewDescription.replace(/(<([^>]+)>)/gi, '')}
        />
        <meta name="twitter:image" content={previewImage} />

        <link rel="shortcut icon" href="/favicon.ico" />
      </Head>
      <Script
        id="ze-snippet"
        strategy="afterInteractive"
        src={`https://static.zdassets.com/ekr/snippet.js?key=${process.env.NEXT_PUBLIC_ZENDESK_KEY}`}
      />
      <Script
        id="adroll-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            adroll_adv_id = "B4CMC7QTFZFFVMMGQBGGQT";
            adroll_pix_id = "EQS5GW4WMJAWNGUJ7R2DUA";
            adroll_version = "2.0";
            (function(w, d, e, o, a) {
              w.__adroll_loaded = true;
              w.adroll = w.adroll || [];
              w.adroll.f = ['setProperties', 'identify', 'track'];
              var roundtripUrl = "https://s.adroll.com/j/" + adroll_adv_id + "/roundtrip.js";
              for (a = 0; a < w.adroll.f.length; a++) {
                w.adroll[w.adroll.f[a]] = w.adroll[w.adroll.f[a]] || (function(n) { return function() { w.adroll.push([ n, arguments ]) } })(w.adroll.f[a]);
              }
              e = d.createElement('script');
              o = d.getElementsByTagName('script')[0];
              e.async = 1;
              e.src = roundtripUrl;
              o.parentNode.insertBefore(e, o);
            })(window, document);
            adroll.track("pageView");
          `,
        }}
      />
    </>
  )
}

export default SeoHead
